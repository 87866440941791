.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  border-radius: 10px;
  box-shadow: 0 8px 40px rgba(96, 64, 221, 15%);
}

.title {
  margin-right: 45px;
  max-width: 600px;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .title {
    max-width: 400px;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .notification {
    height: auto;
    flex-direction: column;
    padding: 40px 15px;
  }

  .title {
    max-width: 285px;
    text-align: center;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
