.crumbs {
  display: flex;
  flex-wrap: nowrap;
}

.crumb {
  color: #6e4a4a;
}

.link {
  color: var(--blue);
  transition: opacity 0.3s ease-in;
}

.link:hover {
  opacity: 0.8;
}

.split {
  margin: 0 4px;
}
