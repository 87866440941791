.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.2s;
  width: 211px;
  height: 190px;
  margin-left: 30px;
  margin-bottom: 30px;
  border: 1px solid var(--blue);
  border-radius: 12px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .card {
    margin-bottom: 20px;
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000;
}

.card.active {
  background-color: var(--blue);
}

.card.active .title {
  color: #fff;
}

.card.active .icon svg {
  fill: #fff;
}

.card:hover {
  background-color: var(--blue);
}

.card:hover .title {
  color: #fff;
}

.card:hover .icon svg {
  fill: #fff;
}

.icon {
  margin-bottom: 33px;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .card {
    height: 157px;
    width: 157px;
  }

  .icon {
    margin-bottom: 15px;
  }

  .title {
    font-size: 16px;
  }

  .icon svg {
    width: 70px;
    height: 70px;
  }
}
