.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  flex-direction: column;
}

.helper_text {
  font-weight: 600;
  font-size: 18px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  color: #000;
  margin-bottom: 20px;
}

.input {
  border: none;
  border-bottom: 1px solid #696969;
  outline: none;
  padding: 0;
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #696969;
  background-color: transparent;
}

.input::placeholder {
  color: #696969;
}

.error {
  display: inline-block;
  margin-top: 10px;
  font-size: 12px;
  color: red;
  line-height: 1.75;
}
