.page {
  padding-bottom: 75px;
}

.intro {
  background: linear-gradient(180deg, #dedef5 0%, rgba(248, 248, 255, 0%) 100%);
  margin-bottom: 80px;
}

.works_list {
  margin-bottom: 0;
}

.intro_inner {
  display: flex;
  justify-content: space-between;
  min-height: 700px;
  padding: 200px 0 160px;
}

.intro_start {
  max-width: 957px;
}

.intro_title {
  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #777793;
  margin-bottom: 30px;
}

.intro_text {
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 142%;
  color: #111035;
  margin-bottom: 40px;
}

.intro_end {
  display: flex;
  align-self: flex-start;
}

.devided {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(123, 123, 156, 0%) 0%,
    #7b7b9c 49.94%,
    rgba(123, 123, 156, 0%) 100%
  );
}

.works {
  margin: 75px 0;
}

.posts {
  margin: 150px 0;
}

.works_title {
  text-align: center;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #777793;
  margin-bottom: 120px;
}

.works_btn {
  display: flex;
  justify-content: center;
}

.works_all {
  display: flex;
  justify-content: center;
}

.notification {
  margin: 75px 0;
}

@media (max-width: 1200px) {
  .notification {
    margin-bottom: 40px;
  }
}

@media (max-width: 1024px) {
  .intro_inner {
    flex-direction: column-reverse;
    padding: 130px 0 20px;
    min-height: 600px;
  }

  .intro_end {
    justify-content: center;
    width: 100%;
  }

  .intro_end img {
    width: 100%;
  }

  .works_title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 50px;
  }

  .intro_title {
    font-size: 30px;
    line-height: 120%;
    max-width: 350px;
    text-align: center;
    margin: 0 auto 20px;
  }

  .intro_text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .intro_button {
    width: 100%;
    height: 60px;
  }

  .intro_button span {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .intro {
    margin-bottom: 40px;
  }

  .works {
    margin: 0;
  }

  .notification {
    margin: 0;
  }

  .posts {
    margin: 40px 0;
  }
}
