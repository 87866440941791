.banner {
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  height: 224px;
}

.banner + .banner {
  margin-top: 60px;
}

.banner_start {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.banner_end {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-left: 1px solid #cfcfcf;
}

@media (max-width: 767px) {
  .banner {
    height: 180px;
  }

  .banner_start,
  .banner_end {
    padding: 20px;
  }

  .banner + .banner {
    margin-top: 30px;
  }
}

@media (max-width: 360px) {
  .banner {
    height: 140px;
  }
}
