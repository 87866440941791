.domains {
  padding: 75px 0;
}

.domains_title {
  font-family: Prompt, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #777793;
  margin-bottom: 80px;
}

.domains_list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px 45px;
  flex-wrap: wrap;
}

.domains_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
  border-radius: 12px;
  max-width: 254px;
  width: 100%;
  padding-bottom: 20px;
}

.domains_icon {
  margin-bottom: 20px;
  width: 100%;
}

.domains_icon img {
  width: 100%;
}

.domains_label {
  font-family: Prompt, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000;
}

@media (max-width: 787px) {
  .domains {
    padding: 40px 0 0;
  }

  .domains_title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 0;
  }

  .domains_list {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    gap: 15px;
    padding: 30px 15px 60px;
    width: calc(100% + 30px);
    margin-left: -15px;
    justify-content: flex-start;
  }

  .domains_item {
    min-width: 105px;
    padding-bottom: 10px;
  }

  .domains_label {
    font-size: 10px;
    line-height: 15px;
  }

  .domains_icon {
    margin-bottom: 12px;
  }
}
