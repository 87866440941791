.awards {
  margin: 75px 0;
}

.awards_title {
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: #777793;
  margin-bottom: 120px;
}

.awards_itemImage img {
  width: 100%;
}

.lead {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  background: #fff;
  box-shadow: 0 8px 40px rgba(96, 64, 221, 15%);
  border-radius: 12px;
  padding: 20px;
}

.lead_text {
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  max-width: 600px;
  color: #111035;
}

.lead_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.awards_list {
  display: flex;
  padding: 100px 0;
  gap: 29px;
}

.awards_item {
  flex-basis: calc(18% - 29px);
  background: #fff;
  box-shadow: 0 8px 40px rgba(96, 64, 221, 15%);
  border-radius: 12px;
  padding: 20px 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.awards_itemTitle {
  font-family: Prompt, sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #777793;
  margin-bottom: 10px;
}

.awards_itemText {
  margin-top: auto;
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111035;
}

@media (max-width: 1280px) {
  .awards_title {
    margin-bottom: 100px;
  }
}

@media (max-width: 1366px) {
  .awards_list {
    flex-wrap: wrap;
    margin-right: -29px;
  }

  .awards_item {
    flex-basis: calc(33.3333% - 29px);
  }
}

@media (max-width: 768px) {
  .awards {
    margin: 40px 0;
  }

  .awards_title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 50px;
  }

  .lead {
    flex-direction: column;
    gap: 40px;
  }

  .lead_text {
    font-size: 16px;
    line-height: 24px;
  }

  .lead_image img {
    width: 167px;
  }

  .awards_list {
    flex-wrap: nowrap;
    overflow: auto;
    margin: 0;
    margin-left: -15px;
    width: calc(100% + 30px);
    padding: 50px 15px;
    gap: 15px;
  }

  .awards_item {
    flex-basis: 157px;
    flex-shrink: 0;
  }

  .awards_itemImage img {
    width: 117px;
  }

  .awards_itemTitle {
    font-size: 14px;
    line-height: 21px;
  }

  .awards_itemText {
    font-size: 12px;
    line-height: 18px;
  }
}
