.footer {
  background-color: var(--dark-blue);
  padding: 30px 0 15px;
}

.footer .footer__start {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.footer__middle {
  display: flex;
  align-items: center;
}

.footer__nav {
  display: flex;
  align-items: center;
}

.menu__list {
  display: flex;
}

.menu__list-item + .menu__list-item {
  margin-left: 30px;
}

.menu__list-link {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #fff;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.footer__end {
  margin-top: -30px;
  display: flex;
  justify-content: space-between;
}

.footer__rights {
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #d2c0c0;
  align-self: flex-end;
}

.footer__social {
  align-self: flex-end;
  display: flex;
}

.footer__icon + .footer__icon {
  margin-left: 15px;
}

.footer__email,
.footer__phone {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 30px;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  color: #fff;
}

/* LETSLINK */
.footer.letslink {
  background-color: #f8f8f8;
}

.footer.letslink .footer__nav a {
  color: var(--dark-blue);
}

.footer.letslink .footer__middle a {
  color: var(--dark-blue);
}

.footer.letslink .footer__rights {
  color: #8c8c8c;
}

.linkMap {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
  font-family: Prompt, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}

.linkMap + .linkMap {
  margin-top: 30px;
}

.footer.letslink .linkMap {
  color: var(--dark-blue);
}

.footer.letslink .linkMap svg {
  fill: var(--dark-blue);
}

.footer.healthCase {
  background-color: #2a0c3d;
}

/* FITKONNEKT */
.footer.fitkonnekt {
  background-color: #e84444;
}

.footer.fitkonnekt .footer__rights {
  color: #fff;
}

/* KETO */
.footer.keto {
  background-color: #eafbea;
}

.footer.keto .footer__nav a {
  color: var(--keto);
}

.footer.keto .footer__middle a {
  color: var(--keto);
}

.footer.keto .footer__rights {
  color: var(--keto);
  opacity: 0.6;
}

.footer.keto .linkMap {
  color: var(--keto);
}

.footer.keto .linkMap svg {
  fill: var(--keto);
}

@media (max-width: 1160px) {
  .footer__end {
    flex-wrap: wrap;
  }

  .footer__social {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .footer__end {
    margin-top: 0;
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
  }

  .footer__social {
    width: auto;
    margin: 0 auto;
    order: 2;
    margin-bottom: 60px;
  }

  .links {
    order: 3;
    margin-bottom: 60px;
  }

  .footer__rights {
    order: 1;
    align-self: auto;
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .footer__start {
    flex-direction: column;
  }

  .footer__nav {
    justify-content: center;
  }

  .footer__email {
    text-align: center;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer__phone {
    text-align: center;
    margin-right: 0;
  }

  .menu__list {
    flex-direction: column-reverse;
    margin-right: 0;
  }

  .menu__list-item {
    text-align: center;
    margin-bottom: 20px;
  }

  .menu__list-item + .menu__list-item {
    margin-left: 0;
  }

  .menu__list-link {
    padding: 0 1.875rem;
  }

  .footer__middle {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-bottom: 60px;
  }
}
