.linkedin {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
}

.linkedin::before {
  content: '';
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: block;
  height: 45px;
  width: 45px;
  opacity: 0;
  transition: 0.3s;
  background-color: #2867b2;
}

.linkedin:hover::before {
  opacity: 1;
}

.linkedin:hover path {
  fill: var(--white);
}

.instagram {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
}

.instagram::before {
  content: '';
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: block;
  height: 45px;
  width: 45px;
  opacity: 0;
  transition: 0.3s;
  background: linear-gradient(
    49.12deg,
    #fd5 6.62%,
    #ff543e 50.06%,
    #c837ab 93.5%
  );
}

.instagram:hover::before {
  opacity: 1;
}

.instagram:hover path {
  fill: var(--white);
}

.dribble {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
}

.dribble::before {
  content: '';
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: block;
  height: 45px;
  width: 45px;
  opacity: 0;
  transition: 0.3s;
  background-color: #e94c8a;
}

.dribble:hover::before {
  opacity: 1;
}

.dribble:hover path {
  fill: var(--white);
}

.clutch {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
}

.clutch::before {
  content: '';
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: block;
  height: 45px;
  width: 45px;
  opacity: 0;
  transition: 0.3s;
  background-color: #504fe9;
}

.clutch:hover::before {
  opacity: 1;
}

.clutch:hover path {
  fill: var(--white);
}

.behance {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
}

.behance::before {
  content: '';
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: block;
  height: 45px;
  width: 45px;
  opacity: 0;
  transition: 0.3s;
  background-color: #0057ff;
}

.behance:hover::before {
  opacity: 1;
}

.behance:hover path {
  fill: var(--white);
}

.link {
  position: relative;
  z-index: 5;
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.link svg {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
