.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -70px;
  margin-bottom: 80px;
}
@media (max-width: 1365px) {
  .list {
    margin-left: -50px;
  }
}
@media (max-width: 1200px) {
  .list {
    margin-bottom: 30px;
  }
}
