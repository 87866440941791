.title {
  margin-bottom: 30px;
}

.text {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: var(--dark-blue);
  margin: 0;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .text {
    font-size: 16px;
    line-height: 24.19px;
  }
}

.section {
  margin-bottom: 60px;
}

.section img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}
