.wrapper {
  width: 100%;
  margin-bottom: 150px;
}

.tab-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-list::-webkit-scrollbar {
  display: none;
}

.tab-item {
  padding: 40px 20px 20px;
  max-width: 238px;
  height: 244px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
}

.tab-item + .tab-item {
  margin-left: 30px;
}

.tab-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.tab-icon.active svg path {
  stroke: var(--white);
}

.tab-icon.active svg rect {
  stroke: var(--white);
}

.tab-icon.active svg circle {
  stroke: var(--white);
}

.tab-title {
  color: var(--black);
  font-size: 24px;
}

.tab-item.active {
  background-color: var(--blue);
}

.tab-item.active .tab-title {
  color: var(--white);
}

.content {
  width: 100%;
}

.content-item {
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.content-item.active {
  opacity: 1;
  height: auto;
  visibility: visible;
  transition: 1s;
}

.content-top {
  display: flex;
  margin-bottom: 60px;
}

.content-start {
  max-width: 200px;
  width: 100%;
  margin-right: 10px;
}

.content-start__title,
.content-end__title,
.content-description__title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1rem;
  color: var(--blue);
}

.content-end__item {
  position: relative;
  padding-left: 20px;
}

.content-end__item::before {
  content: '-';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1365px) {
  .tab-list {
    justify-content: flex-start;
    overflow-x: auto;
    margin-left: -75px;
    padding: 75px;
    width: calc(100% + 150px);
    margin-bottom: 0;
  }
}

@media (max-width: 1200px) {
  .tab-list {
    overflow-y: hidden;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    margin-bottom: 0;
  }

  .tab-list {
    width: calc(100% + 30px);
    padding: 50px 15px;
    margin-bottom: 10px;
    margin-left: -15px;
    background-color: #f8f8ff;
  }

  .tab-item {
    width: 95px;
    height: 100px;
    padding: 16px 9px 9px;
    border-radius: 0;
  }

  .tab-icon svg {
    width: 30px;
    height: 30px;
  }

  .tab-title {
    font-size: 12px;
  }

  .content-top {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .content-start {
    margin-bottom: 30px;
  }
}
