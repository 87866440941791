.list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-left: -30px;
}

.title {
  margin-bottom: 120px;
  text-align: center;
  color: #777793;
}

@media (max-width: 1200px) {
  .title {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .list {
    margin-bottom: 10px;
  }
}
