.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 24px;
  font-weight: 200;
  color: #616161;
  display: inline-block;
  margin-bottom: 10px;
}

.textarea {
  padding: 1.25rem 0.9375rem;
  background: #fcfcfc;
  border: 1px solid #bebebe;
  box-sizing: border-box;
  border-radius: 8px;
  height: 166px;
  resize: none;
  outline: 0;
}

.textarea.error {
  border: 1px solid red;
}
