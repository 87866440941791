.wrapper {
  overflow: hidden;
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(0);
  margin-left: 20px;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  pointer-events: none;
}

.slide {
  min-width: 296px;
  flex-shrink: 0;
  pointer-events: none;
}

.slide + .slide {
  margin-left: 36px;
}

.slide > div {
  width: 296px;
}
