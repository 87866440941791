.wrapper {
  flex-basis: calc(33.3333% - 30px);
  max-width: 463px;
  margin-left: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  align-items: flex-start;
  transition: box-shadow 0.3s linear;
  border-radius: 12px;
}

.image {
  font-size: 0;
  margin-bottom: 20px;
}

.wrapper img {
  width: 100%;
  object-fit: cover;
}

.link {
  display: block;
  color: #000;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 27px;
  transition: color 0.3s;
}

.link:hover {
  color: var(--blue);
}

.content {
  padding: 20px;
}

.wrapper:hover {
  box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
}

@media (max-width: 1200px) {
  .wrapper {
    flex-basis: calc(50% - 30px);
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .content {
    padding: 15px;
  }

  .link {
    font-size: 15px;
    line-height: 20px;
  }

  .wrapper {
    flex-basis: calc(100% - 30px);
    max-width: 100%;
  }
}
