.slider {
  position: relative;
  max-width: 1920px;
  padding: 0 7.5rem;
  width: 100%;
  max-height: 541px;
  margin: 0 auto;
}

.wrapper {
  max-height: 541px;
}

.dots {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.dot {
  width: 8px;
  height: 8px;
  cursor: pointer;
  background-color: var(--gray-light);
  transition: 0.2s;
  border-radius: 50%;
}

.dot__active {
  background-color: var(--blue);
}

.dot + .dot {
  margin-left: 10px;
}

.button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
}

.button--left {
  left: 0;
}

.button--right {
  right: 0;
}

@media (max-width: 1024px) {
  .slider {
    padding: 0 3.5rem;
  }
}

@media (max-width: 768px) {
  .slider {
    padding: 0;
  }

  .dots {
    display: flex;
    bottom: 0;
  }

  .button {
    display: none;
  }
}
