/* stylelint-disable */

.button {
  position: relative;
  z-index: 2;
  display: inline-block;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4;
  padding: 0.75rem 1.25rem;
  transition: 0.3s;
  cursor: pointer;
}

.container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 12px;
}

.container + .container {
  margin-left: 10px;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 1;
  display: block;
  width: 110%;
  height: 110%;
  transform: translateX(-100%);
  transition: 0.3s;
}

.container:hover::before {
  transform: translateX(0);
}

/* PRIMARY */
.primary {
  background-color: var(--blue);
  border: 1px solid var(--blue);
}

.primary.container .button {
  color: var(--white);
}

.primary.container::before {
  background-color: var(--white);
}

.primary.container:hover .button {
  color: var(--blue);
}

/* GHOST */
.ghost {
  background-color: var(--white);
  border: 1px solid var(--blue);
}

.ghost.container .button {
  color: var(--blue);
}

.ghost.letslink .button {
  color: var(--letslink);
}

.ghost.container::before {
  background-color: var(--blue);
}

.ghost.container:hover .button {
  color: var(--white);
}

/* LETSLINK */
.letslink {
  background-color: var(--white);
  border: 1px solid var(--white);
}

.letslink.container::before {
  background-color: var(--letslink);
}

.letslink.container:hover .button {
  color: var(--white);
}

/* FITKONNENT */
.fitkonnekt {
  border-radius: 50px;
  background-color: #e84444;
  transition: 0.3s;
}

.fitkonnekt:hover {
  background: #f50d0d;
}

.fitkonnekt:active {
  background: #dd4242;
}

.fitkonnekt.container .button {
  color: var(--white);
}

/* HEALTHCASE */

.healthCase {
  background: #ad3ebf;
  box-shadow: 0 4px 6px rgba(94, 33, 104, 15%);
  border-radius: 12px;
  transition: box-shadow 0.3s linear;
}

.healthCase:hover {
  box-shadow: 0 4px 6px rgba(95, 33, 104, 57%);
}

.healthCase.container .button {
  color: #fff;
}

/* KETO */
.keto {
  background-color: transparent;
  border: none;
  transition: 0.3s;
  border-radius: 12px;
}

.keto.container .button {
  color: #333;
}

.keto.container:hover .button {
  color: var(--white);
}

.keto.container::before {
  background-color: var(--keto);
}

/* NFT */
.nft {
  background-color: #2c34f1;
  transition: 0.3s;
  box-shadow: 0 6px 6px rgba(59, 11, 67, 15%);
  border-radius: 12px;
  min-width: 248px;
}
.nft:hover {
  background: #6a3ccf;
}
.nft:active {
  background: #6a3ccf;
}
.nft.container .button {
  color: var(--white);
  text-transform: none;
  font-weight: 600;
  width: 100%;
  font-size: 17px;
  line-height: 21px;
  padding: 16px 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
}

@media (max-width: 1440px) {
  .button {
    padding: 9px 20px;
  }

  .keto {
    background-color: var(--white);
    border: 1px solid var(--white);
  }
}

@media (max-width: 767px) {
  .button {
    font-size: 14px;
  }
}

/* DESIGN */
.design {
  background-color: #504fe9;
  border: none;
  transition: 0.3s;
  border-radius: 12px;
  box-shadow: 0px 6px 20px rgba(96, 64, 221, 0.25);
}

.design.container .button {
  color: #fff;
  font-weight: 500;
  padding: 26px 38px;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  width: 100%;
}

.design.container:hover .button {
  color: #504fe9;
}

.design.container::before {
  background-color: #fff;
}

@media (max-width: 1280px) {
  .design.container .button {
    font-size: 18px;
  }
}

@media (max-width: 950px) {
  .design {
    border-radius: 12px;
  }

  .design.container .button {
    text-align: center;
    padding: 13px 10px;
  }
}
