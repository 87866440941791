.card {
  text-align: center;
  padding: 23px;
  background-color: var(--light-gray);
  border-radius: 12px;
  height: 100%;
}

.icon {
  margin-bottom: 30px;
}

.title {
  margin-bottom: 30px;
}

.text {
  font-weight: 200;
  color: var(--gray-light);
}

.markup {
  position: relative;
  padding-left: 10px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  color: #616161;
}

.markup::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  left: 0;
  top: 16px;
  background-color: #616161;
}

@media (max-width: 1024px) {
  .list {
    flex-direction: column;
    margin-left: 0;
  }

  .item {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .markup::before {
    top: 10px;
  }
}
