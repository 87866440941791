.container_fluid {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.container {
  max-width: 1600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 75px;
}

.container_medium {
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.container_small {
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

@media (max-width: 1024px) {
  .container {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .container,
  .container_medium,
  .container_small {
    padding: 0 15px;
  }
}
