.tag {
  padding: 10px 16px;
  border-radius: 14px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.055em;
}

.tag__green {
  background: rgba(0, 176, 71, 20%);
  color: #208d4c;
}

.tag__white {
  background: #f1f1f1;
  color: #5d5d5d;
}

.tag__greenLight {
  background: #ccefda;
  color: #208d4c;
}

.tag__gray {
  background: #e8e8e8;
  color: #5d5d5d;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  cursor: pointer;
}

.tags.disabled {
  opacity: 0.3;
}

.tags_item {
  margin-bottom: 25px;
  margin-left: 25px;
}
