.platform {
  padding: 75px 0;
}

.platform_title {
  font-family: Prompt, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #777793;
  margin-bottom: 80px;
}

.platform_inner {
  background: #fff;
  box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
  border-radius: 12px;
  padding: 60px 120px;
}

.platform_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.platform_secondList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.platform_secondListItem {
  font-family: Prompt, sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 39px;
  color: #000;
}

@media (max-width: 1024px) {
  .platform_inner {
    padding: 30px;
  }
}

@media (max-width: 787px) {
  .platform {
    padding: 0;
  }

  .platform_title {
    font-size: 22px;
    line-height: 120.1%;
    margin-bottom: 30px;
  }

  .platform_list {
    flex-direction: column;
    gap: 10px;
  }

  .platform_secondListItem {
    font-size: 16px;
    line-height: 24px;
  }

  .platform_secondList {
    justify-content: center;
    align-items: center;
  }
}
