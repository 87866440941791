.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.contained {
  background-color: var(--dark-blue);
}

.transparent {
  background-color: transparent;
}

.header_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 102px;
}

.header_nav {
  margin-left: auto;
}

.header_logo {
  max-width: 355px;
  position: relative;
  z-index: 11;
}

.header_logo svg {
  width: 100%;
}

.buttons {
  margin-left: 60px;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.burger {
  display: none;
  position: relative;
  width: 25px;
  height: 25px;
  z-index: 12;
}

.burger::before,
.burger::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  display: block;
  background-color: var(--blue);
  border-radius: 10px;
  transition: 0.3s;
}

.burger.burger_letslink::before,
.burger.burger_letslink::after {
  background-color: var(--letslink);
}

.burger.burger_healthCase::before,
.burger.burger_healthCase::after {
  background-color: #000;
}

.burger.burger_fitkonnekt::before,
.burger.burger_fitkonnekt::after {
  background-color: var(--black);
}

.burger.burger_keto::before,
.burger.burger_keto::after {
  background-color: var(--keto);
}

.burger.burger_nft::before,
.burger.burger_nft::after {
  background-color: #7748d9;
}

.burger::before {
  top: 5px;
}

.burger::after {
  bottom: 5px;
}

.header__menu_active .burger::before,
.header__menu_active .burger::after {
  background-color: var(--white);
}

.header__menu_active .burger::before {
  transform: rotate(45deg);
  top: 11px;
}

.header__menu_active .burger::after {
  transform: rotate(-45deg);
  bottom: 11px;
}

@media (max-width: 1024px) {
  .header {
    position: fixed;
    background-color: var(--white);
    box-shadow: 0 10px 20px rgb(50, 50, 50, 10%);
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--blue);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 120px;
    transform: translateY(-100%);
  }

  .wrapper.wrapper_letslink {
    background-color: var(--letslink);
  }

  .wrapper.wrapper_fitkonnekt {
    background-color: #e84444;
  }

  .wrapper.wrapper_healthCase {
    background-color: #ad3ebf;
  }

  .wrapper.wrapper_keto {
    background-color: var(--keto);
  }

  .wrapper.active {
    transform: translateY(0);
    transition: transform 0.5s;
    overflow-y: auto;
  }

  .burger {
    display: block;
  }

  .header_inner {
    height: 70px;
  }

  .header_nav {
    margin-left: 0;
    text-align: center;
  }

  .buttons {
    margin-left: 0;
  }
}
