.form__top,
.form__bottom {
  display: flex;
  width: 100%;
  margin-bottom: 60px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.name,
.phone {
  margin-left: 30px;
}
@media (max-width: 1200px) {
  .form__top,
  .form__bottom {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .name,
  .phone {
    margin-left: 0;
  }

  .email,
  .body {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .form {
    margin-bottom: 30px;
  }
}
