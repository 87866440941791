.sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 2rem;
}

.title {
  margin-bottom: 20px;
  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.item {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.link {
  transition: 0.3s;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6e4a4a;
}

.link.active {
  color: var(--blue);
}

.count {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.3s;
  margin-right: 8px;
  margin-top: 2px;
  border: 1px solid #6e4a4a;
}

.count.active {
  background-color: var(--blue);
  color: var(--white);
}

@media (max-width: 900px) {
  .sidebar {
    position: relative;
    top: 0;
  }

  .link {
    font-size: 16px;
    line-height: 19.5px;
  }
}
