.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}

.item {
  margin-left: 30px;
  flex-basis: calc(33.3333% - 30px);
}
