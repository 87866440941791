.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-top: 30px;
}

@media (max-width: 1366px) {
  .list {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .list {
    justify-content: center;
  }
}
