.wrapper {
  max-width: 350px;
}

.list_head {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.head_icon {
  margin-right: 15px;
}

.head_title {
  font-family: Prompt, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000;
}

.list {
  padding-left: 15px;
}

.list_item {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  font-family: Prompt, sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #000;
}

.list_item::before {
  position: absolute;
  top: 15px;
  left: 0;
  content: '';
  height: 3px;
  width: 3px;
  display: block;
  border-radius: 50%;
  background-color: #000;
}

.list_item:last-child {
  margin-bottom: 0;
}

@media (max-width: 1440px) {
  .head_icon {
    margin-right: 16px;
  }

  .head_icon svg {
    width: 44px;
    height: 44px;
  }

  .head_title {
    font-size: 18px;
    line-height: 22px;
  }

  .list_head {
    margin-bottom: 17px;
  }

  .list {
    max-width: 262px;
    padding-left: 7px;
  }

  .list_item {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
  }

  .list_item::before {
    top: 10px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    max-width: 100%;
    width: 100%;
  }

  .head_icon {
    margin-right: 12px;
  }

  .head_title {
    width: 100%;
    font-size: 15px;
  }

  .list {
    padding-left: 60px;
    max-width: 100%;
  }
}
