.paragraph {
  font-weight: 200;
  font-size: 24px;
  line-height: 36px;
  color: var(--dark-blue);
  margin: 0;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.justyfy {
  text-align: justify;
}

@media (max-width: 768px) {
  .paragraph {
    font-size: 16px;
    line-height: 24.19px;
  }
}
