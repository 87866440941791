.title {
  margin-bottom: 50px;
}

.text {
  margin-bottom: 50px;
  max-width: 436px;
}

.button {
  display: flex;
  justify-content: center;
}
