.card {
  max-width: 690px;
  margin-left: 70px;
  -ms-flex-preferred-size: calc(50% - 70px);
  flex-basis: calc(50% - 70px);
  transition: box-shadow 0.3s linear;
  overflow: hidden;
  margin-bottom: 70px;
}

.card:hover {
  box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
}

.content {
  padding: 30px 20px;
}

.images {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0;
  transition: transform 0.5s;
}

.images div:nth-child(2) {
  position: absolute;
  transform: rotateY(-180deg);
  bottom: -70px;
  right: -70px;
}

.img {
  width: 100%;
}

.title {
  margin-bottom: 10px;
  color: var(--dark-blue);
  transition: color 0.3s;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.title:hover {
  color: var(--blue);
}

.text {
  margin-bottom: 20px;
  font-family: Prompt, sans-serif;
  font-weight: 200;
  font-size: 24px;
  line-height: 120%;
  color: #111035;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
}

.item {
  margin-left: 7px;
}

.tag {
  font-size: 24px;
  color: var(--blue);
}

@media (max-width: 1365px) {
  .card {
    margin-left: 50px;
    -ms-flex-preferred-size: calc(50% - 50px);
    flex-basis: calc(50% - 50px);
  }
}
@media (max-width: 1200px) {
  .card {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .card {
    max-width: 100%;
    flex-basis: calc(100%);
    margin-bottom: 30px;
    box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
  }

  .content {
    padding: 15px 10px 10px;
  }

  .tag {
    font-size: 16px;
  }

  .card:first-child {
    margin-top: auto;
  }

  .card:nth-child(2n) {
    margin-top: auto;
  }

  .card:nth-child(2n + 1) {
    margin-top: auto;
  }
}
