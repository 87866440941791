.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0%);
  transition: background-color 0.3s ease-in;
}

.window {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  transition: transform 0.3s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal.open .window {
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.modal.open .overlay {
  background-color: rgba(0, 0, 0, 50%);
}

.modal.close .window {
  transform: translateX(-50%) translateY(-50%) scale(0);
}

.modal.close .overlay {
  background-color: rgba(0, 0, 0, 0%);
}
