.wrapper {
  margin-top: 80px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -30px;
}

.title {
  margin-bottom: 10px;
}

.item {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-left: 30px;
  font-family: Prompt, sans-serif;
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  color: #111035;
}

.two {
  flex-basis: calc(50% - 30px);
}

.four {
  flex-basis: calc(25% - 30px);
}

.item svg {
  flex-shrink: 0;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .wrapper {
    margin-top: 15px;
  }

  .list {
    margin-left: -10px;
  }

  .title {
    display: none;
  }

  .item {
    flex-basis: calc(50% - 10px);
    margin-left: 10px;
    font-size: 12px;
    line-height: 14px;
  }

  .item svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
}
