.form_box {
  max-width: 500px;
  width: 100%;
  margin: 0 auto 70px;
}

.form_title {
  font-weight: 700;
  font-size: 38px;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #435445;
}

.form_subtitle {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  color: #696969;
}

.form_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_start {
  max-width: 474px;
  width: 100%;
  margin-right: 30px;
}

.form_input {
  margin-bottom: 30px;
}

.form_end {
  max-width: 373px;
  width: 100%;
}

.button {
  margin-top: 30px;
  background: #44ba52;
  border-radius: 12px;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background 0.3s linear;
}

.button:hover {
  background: #3fcc4f;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 767px) {
  .form_box {
    margin-bottom: 20px;
  }

  .form_title {
    font-size: 26px;
    margin-bottom: 10px;
    text-align: left;
  }

  .form_subtitle {
    font-size: 16px;
    text-align: left;
  }

  .form_inner {
    flex-direction: column-reverse;
  }

  .form_start {
    margin-right: 0;
  }

  .form_end {
    margin-bottom: 30px;
  }
}
