.wrapper {
  overflow: hidden;
}

.scrolling {
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(0);
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  padding: 0 2.5rem;
  pointer-events: none;
}

.item {
  min-width: 375px;
  margin: 15px 0;
  box-shadow: 0 4px 12px rgb(167, 167, 167, 25%);
  pointer-events: none;
}

.item + .item {
  margin-left: 37.5px;
}

.item > div {
  width: 375px;
}

@media (max-width: 767px) {
  .item {
    min-width: 133px;
  }

  .item > div {
    width: 133px;
  }
}
