.timeline_title {
  font-weight: 700;
  font-size: 60px;
  line-height: 140%;
  text-transform: uppercase;
  color: #435445;
  margin-bottom: 10px;
}

.timeline_text {
  margin-bottom: 80px;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.055em;
  text-align: justify;
  color: #696969;
}

.timeline_inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.progress {
  position: relative;
  margin-bottom: 100px;
  align-self: center;
}

.progress_circle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 216px;
  margin: 0 auto;
}

.progress_circle svg {
  transform: rotate(-90deg);
}

.progress_circle svg circle:nth-child(1),
.progress_circle svg circle:nth-child(2) {
  width: 216px;
  height: 216px;
  fill: none;
  stroke-width: 15;
  stroke: #f4f6fb;
}

.progress_circle svg circle:nth-child(3) {
  stroke: #1bbe5c;
  stroke-width: 15;
  stroke-dasharray: 630px;
  stroke-dashoffset: 630px;
  transition: all 1s linear;
  stroke-linecap: round;
}

.progress_circle svg circle:nth-child(4) {
  stroke: #8dd0a8;
  stroke-width: 15;
  stroke-dasharray: 500px;
  stroke-dashoffset: 500px;
  transition: all 1s linear;
  stroke-linecap: round;
}

.progress_statistics {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #435445;
  text-align: center;
}

.progress_percent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #435445;
  margin-bottom: 10px;
}

.progress_percent span {
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #435445;
}

.progress_coffee {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  display: flex;
  margin-bottom: 50px;
  width: 978px;
}

.hidden {
  width: auto;
  overflow-x: auto;
}

.button {
  position: relative;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.4;
  color: #435445;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 17px;
}

.button.active {
  transform: scale(1.05);
  font-weight: 600;
  transition: transform 0.8s;
}

.button.active::before {
  height: 4px;
}

.button:first-child {
  margin-left: 0;
}

.button::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  border-radius: 2px;
}

.ba {
  padding: 0 38px 25px;
}

.ba::before {
  background: #00b047;
}

.visualDesign {
  padding: 0 9px 25px;
}

.visualDesign::before {
  background: #435445;
}

.development {
  max-width: 496px;
  width: 100%;
  padding: 0 38px 25px;
}

.development::before {
  background: #00b047;
}

.qa {
  padding-bottom: 25px;
}

.qa::before {
  background: #435445;
}

.tags {
  display: flex;
  width: 978px;
}

.tags_item + .tags_item {
  margin-left: 15px;
}

.tagsBa {
  width: 100%;
  max-width: 187px;
}

.tagsDesign {
  width: 100%;
  max-width: 160px;
}

.tagsDevelopment {
  width: 100%;
  max-width: 334px;
}

.tagsQa {
  width: 100%;
  max-width: 272px;
}

ul.tags__last {
  flex-direction: column;
}

ul.tags__last li {
  align-self: flex-end;
  margin-left: 0;
}

.marker {
  position: absolute;
  right: -180px;
  top: 50%;
  transform: translateY(-50%);
}

.marker_item {
  position: relative;
  padding-left: 25px;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #525e8a;
}

.marker_item + .marker_item {
  margin-top: 20px;
}

.marker_item::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #1bbe5c;
}

.marker_item.cup::before {
  background-color: #8dd0a8;
}

@media (max-width: 767px) {
  .timeline_title {
    font-size: 32px;
  }

  .timeline_text {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .marker {
    right: auto;
    top: auto;
    bottom: -80px;
    transform: translateY(0);
  }

  .progress {
    margin-bottom: 140px;
  }
}
