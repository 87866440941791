.hoverline > * {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.hoverline > *::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--blue);
  transform: translateX(-101%);
  transition: 0.3s;
}

.hoverline.white > *::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--white);
  transform: translateX(-101%);
  transition: 0.3s;
}

.hoverline.letslink > *::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--letslink);
  transform: translateX(-101%);
  transition: 0.3s;
}

.hoverline.fitkonnekt > *::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #e84444;
  transform: translateX(-101%);
  transition: 0.3s;
}

.hoverline.keto > *::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--keto);
  transform: translateX(-101%);
  transition: 0.3s;
}

.hoverline.healthCase > *::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #3c1143;
  transform: translateX(-101%);
  transition: 0.3s;
}

.hoverline > *:hover::before {
  transform: translateX(0);
}
