.h1 {
  font-weight: 700;
  font-size: 72px;
  line-height: 86px;
  letter-spacing: 0.05em;
  margin: 0;
  font-family: Ubuntu, sans-serif;
}

.h2 {
  font-weight: 700;
  font-size: 56px;
  line-height: 74px;
  letter-spacing: 0.05em;
  margin: 0;
  font-family: Ubuntu, sans-serif;
}

.h3 {
  font-weight: 700;
  font-size: 38px;
  line-height: 45.64px;
  margin: 0;
  font-family: Ubuntu, sans-serif;
}

.h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48.38px;
  margin: 0;
}

.h5 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.05em;
  margin: 0;
}

.h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27.22px;
  margin: 0;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.justyfy {
  text-align: justify;
}

.strong {
  color: var(--dark-blue);
}

.light {
  color: var(--blue);
}

@media (max-width: 768px) {
  .h1 {
    font-size: 28px;
    line-height: 33.63px;
  }

  .h2 {
    font-size: 22px;
    line-height: 26.4px;
  }

  .h3 {
    font-size: 22px;
    line-height: 26.4px;
  }

  .h4 {
    font-size: 20px;
    line-height: 30.24px;
  }

  .h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
  }

  .h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24.19px;
  }
}
