.wrapper {
  display: flex !important;
  max-width: 100%;
}

.info {
  max-width: 100%;
}

.image {
  border-radius: 50%;
  width: 92px;
  height: 92px;
  margin-right: 30px;
  flex-shrink: 0;
  overflow: hidden;
}

.text {
  margin-bottom: 20px;
}

.name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #474444;
}

.company {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--blue);
}

@media (max-width: 768px) {
  .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 0;
    padding-bottom: 1.25rem;
  }

  .image {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .name {
    font-size: 16px;
  }

  .company {
    font-size: 16px;
  }
}
