.development {
  padding-bottom: 75px;
}

.development_title {
  font-family: Prompt, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #777793;
  margin-bottom: 80px;
}

.development_text {
  font-family: Prompt, sans-serif;
  font-weight: 275;
  font-size: 22px;
  line-height: 142%;
  text-align: center;
  color: #111035;
  margin-bottom: 80px;
}

.development_list {
  display: flex;
  gap: 30px;
}

.development_item {
  background: #fff;
  box-shadow: 0 10px 40px rgba(96, 64, 221, 20%);
  border-radius: 12px;
  overflow: hidden;
}

.development_itemImg {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.development_itemImg img {
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.development_itemImg:hover img {
  transform: scale(1.1);
}

.development_footer {
  padding: 30px 20px;
}

.development_itemName {
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 51px;
  color: #000;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.development_itemName:hover {
  color: var(--blue);
}

.development_itemContent {
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  color: #000;
}

@media (max-width: 787px) {
  .development {
    padding-bottom: 0;
  }

  .development_title {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .development_text {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .development_list {
    gap: 15px;
    padding: 30px 15px 60px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  .development_item {
    min-width: 280px;
  }

  .development_itemName {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  .development_footer {
    padding: 20px 16px;
  }

  .development_itemContent {
    font-size: 15px;
    line-height: 23px;
  }
}
