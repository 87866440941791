.nav_list {
  display: flex;
}

.nav_item {
  margin-left: 30px;
}

.nav_item a,
.nav_item {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: var(--dark-blue);
}

.nav_item:first-child {
  margin-left: 0;
}

.drop {
  position: relative;
  padding-right: 20px;
}

.drop::before {
  content: '';
  position: absolute;
  top: 25px;
  height: 20px;
  width: 100%;
}

.drop_list {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 43px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  min-width: 100%;
  background: var(--blue);
  border-radius: 6px;
  transition: 0.3s;
}

.drop:hover .drop_list {
  opacity: 1;
  visibility: visible;
}

.drop_item {
  margin-bottom: 15px;
}

.drop_item a {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  white-space: nowrap;
  color: var(--white);
}

.drop_item:last-child {
  margin-bottom: 0;
}

.arrow {
  position: absolute;
  top: 13px;
  right: 0;
  fill: var(--dark-blue);
}

.nav.nav__white .arrow,
.nav.nav__nft .arrow {
  fill: var(--white);
}

.nav.nav__white .drop_list {
  background-color: var(--white);
}

.nav.nav__letslink .drop_list {
  background-color: var(--letslink);
}

.nav.nav__fitkonnekt .drop_list {
  background-color: #727272;
}

.nav.nav__healthCase {
  font-family: Cairo, sans-serif;
}

.nav.nav__healthCase .drop_list {
  background-color: #ad3ebf;
}

.nav.nav__nft {
  font-family: Inter, sans-serif;
}

.nav.nav__keto .drop_list {
  background-color: var(--keto);
}

.nav.nav__white .nav_item a,
.nav.nav__white .nav_item,
.nav.nav__nft .nav_item a,
.nav.nav__nft .nav_item {
  color: var(--white);
}

.nav.nav__white .drop_item a {
  color: var(--dark-blue);
}

@media (max-width: 1024px) {
  .nav_list {
    flex-direction: column;
  }

  .nav_item {
    margin-left: 0;
    margin-bottom: 15px;
  }

  .nav_item a {
    display: block;
  }

  .drop_list {
    margin-top: 15px;
    padding: 0;
    position: static;
    display: none;
    transform: translateX(0);
    background-color: transparent !important;
  }

  .drop {
    padding: 0;
  }

  .arrow {
    right: -20px;
  }

  .drop_item a {
    color: var(--white) !important;
  }

  .drop:hover .drop_list {
    display: block;
  }
}
