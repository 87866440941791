.searchButton {
  width: 211px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #616161;
  border-radius: 12px;
  transition: 0.3s linear border;
  cursor: pointer;
}

@media (max-width: 768px) {
  .searchButton {
    width: 157px;
    height: 157px;
  }
}

.searchButton svg {
  transition: 0.3s linear all;
}

.searchButton:hover {
  border-color: var(--blue);
}

.searchButton:hover svg path {
  stroke: var(--blue);
}
