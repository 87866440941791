.input {
  position: relative;
  width: 100%;
}

.input_searchIcon {
  position: absolute;
  top: 0;
  left: 0;
}

.input_custom {
  font-family: Prompt, sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  color: #7c7c7c;
  padding: 0 66px 30px;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  outline: none;
}

.input_custom:focus {
  border-color: var(--blue);
}

.input_closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
